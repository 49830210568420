// Import Bootstrap functions (needed for customizing variables)
@import "bootstrap/scss/functions";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


// 🚀 Override Bootstrap Button Variables
$btn-padding-y: 0.5rem;
$btn-padding-x: 1.5rem;
$btn-border-radius: 6px;


/* Fonts */
:root {
    --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway",  sans-serif;
    --nav-font: "Poppins",  sans-serif;
  }
  
  /* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
  :root { 
    --background-color: #ffffff; /* Background color for the entire website, including individual sections */
    --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
    --heading-color: #282828; /* Color for headings, subheadings and title throughout the website */
    --accent-color: #610099; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
  }
  
  /* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
  :root {
    --nav-color: #ffffffd5;  /* The default color of the main navmenu links */
    --nav-hover-color: #610099; /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #444444; /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #610099; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}


/* Button Colors */
  :root {
    --primary:  #610099; 
    --secondary: #8b54ca;
    --success: #28a745;
    --danger: #dc3545;
    --warning: #ffc107;
    --info: #17a2b8;
    --light: #f8f9fa;
    --dark: #343a40;  
  }
  
  .light-background {
    --background-color: #f9f9f9;
    --surface-color: #ffffff;
  }
  
  .dark-background {
    --background-color: #181818 !important;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #252525;
    --contrast-color: #ffffff;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }

  .btn  {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px;
    border-radius: 50px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .btn-primary {
    background: var(--accent-color);
  }

  .btn-secondary {
    background: var(--secondary);
  }