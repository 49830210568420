.project-showcase {
    .development-card {
      display: flex;
      overflow: hidden;
      border: none;
      border-radius: 10px;
      background: #fff;
      box-shadow:   0px 5px 60px 0px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      .image-container {
        img {
          width: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
  
      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
      .button-group {
        display: flex;
        gap: 10px;
        margin-top: 15px;
        
        .btn {
          flex: 1;
        }
      }
    }
  }

 // ICON SECTION STYLING 
.icon-section {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.icon {
    width: 35px; 
    height: 35px; 
    padding: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: var(--secondary); // Light background
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    overflow: hidden;
}

// FontAwesome Icons
.icon.fa-icon {

  font-size: 1.8rem; // Adjust icon size
  color: #fff; // Use your theme’s primary color
  transition: transform 0.3s ease, color 0.3s ease;
  max-width: 22px;
  max-height: 22px;

  &:hover {
    transform: scale(1.2); // Slight hover effect
    color: var(--secondary-color); // Use secondary color on hover
    
  }
}

// Bootstrap Icons
.icon.bi-icon {
  font-size: 1.8rem;
  color:#fff;
  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    transform: scale(1.2);
    color: var(--secondary-color);
  }
}

// Adjusting spacing in Project Cards
.development-card {
  padding: 20px;
  box-shadow: 0px 5px 90px 0px rgba(0, 0, 0, 0.1); // Soft shadow
  border-radius: 12px; // Rounded corners
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px); // Lift effect on hover
  }
}

// Button Styling for Showcase
.button-group {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
  .btn  {
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px;
    border-radius: 50px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .btn-primary {
    background: var(--accent-color);
    --bs-btn-color: #fff;
    --bs-btn-border-color: var(--accent-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--secondary);
    --bs-btn-hover-border-color: var(--secondary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--accent-color);
    --bs-btn-active-border-color: var(--accent-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #919191;
    --bs-btn-disabled-border-color: #919191;
  }

  .btn-secondary {
    background: var(--secondary);
    --bs-btn-color: #fff;
    --bs-btn-border-color: var(--secondary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--secondary);
    --bs-btn-active-border-color: var(--secondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #919191;
    --bs-btn-disabled-border-color: #919191;
  }

