.about-page {
  background: #0d0d0d; // Deep dark theme
  color: #f5f5f5; // Light text for contrast

  .about-hero {
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
  
    @media (max-width: 768px) {
      padding-top: 80px; // Adjust this value based on your menu height
    }
  
    @media (max-width: 480px) {
      padding-top: 100px; // Increase for smaller screens if needed
    }
  
  

    .about-slider {
      height: 100%;
    }

    .hero-slide {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; // Centers content better on smaller screens
      background: #181818;
      transition: background 0.6s ease-in-out;
      text-align: center;

      .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
          text-align: center;
        }
      }

      .hero-text {
        text-align: left;
        animation: fadeInUp 1s ease-in-out;

        @media (max-width: 768px) {
          text-align: center;
          padding: 20px;
        }

        h1 {
          font-size: 3rem;
          font-weight: bold;
          color: var(--accent-color);
          margin-bottom: 15px;

          @media (max-width: 768px) {
            font-size: 2.2rem;
          }

          @media (max-width: 480px) {
            font-size: 1.8rem;
          }
        }

        p {
          font-size: 1.4rem;
          color: #ddd;
          margin-bottom: 20px;

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }

          @media (max-width: 480px) {
            font-size: 1rem;
          }
        }

        .btn-primary {
          background: var(--accent-color);
          border: none;
          padding: 12px 24px;
          font-size: 1.2rem;
          transition: all 0.4s ease-in-out;

          &:hover {
            filter: brightness(120%);
            transform: scale(1.05);
          }

          @media (max-width: 768px) {
            font-size: 1rem;
            padding: 10px 20px;
          }
        }
      }

      .hero-image img {
        width: 100%;
        max-width: 450px;
        height: auto;
        object-fit: cover;
        border-radius: 12px;
        box-shadow: 0 6px 20px rgba(255, 255, 255, 0.1);
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.03);
        }

        @media (max-width: 768px) {
          max-width: 300px;
          margin-top: 20px;
        }

        @media (max-width: 480px) {
          max-width: 250px;
        }
      }
    }
  }

  // Keyframe Animations
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
