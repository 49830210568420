.development-card {
    display: flex;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    background: #fff;
    box-shadow:   0px 5px 60px 0px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    .image-container {
      img {
        width: 100%;
        max-height: 250px; 
        object-fit: cover;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .button-group {
      display: flex;
      gap: 10px;
      margin-top: 15px;
      
      .btn {
        flex: 1;
      }
    }
  }
