.icon-row {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.icon-wrapper {
  width: 50px;
  height: 50px;
  background: var(--accent-color, #007bff); /* Default to blue if var not defined */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: var(--contrast-color, black);
  }
}

.icon-small {
  font-size: 1.5rem !important; /* Force icon size */
  color: white !important; /* Force white color */
  opacity: 1 !important;
  display: inline-block;
}

.icon-label {
  font-size: 0.8rem;
  margin-top: 5px;
  text-align: center;
  color: var(--text-color, black); /* Ensure text is visible */
}

/* Pulsating Play Button
  ------------------------------*/
  .pulsating-play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 75%) 52%);
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .pulsating-play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-play-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .pulsating-play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .pulsating-play-btn:hover:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  .pulsating-play-btn:hover:after {
    border-left: 15px solid var(--accent-color);
    transform: scale(20);
  }
  
  @keyframes pulsate-play-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  
    
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .hero .container {
    position: relative;
    z-index: 3;
  }
  
  .hero h2 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
  }
  
  .hero p {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 10px 0 20px 0;
    font-size: 24px;
  }
  
  @media (max-width: 768px) {
    .hero h2 {
      font-size: 32px;
      line-height: 36px;
    }
  
    .hero p {
      font-size: 18px;
      line-height: 24px;
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about ul {
    list-style: none;
    padding: 0;
  }
  
  .about ul li {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .about ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .about .read-more {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 28px;
    border-radius: 50px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .about .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
  }
  
  .about .read-more:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .about .read-more:hover i {
    transform: translate(5px, 0);
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services .service-item {
    background-color: var(--surface-color);
    box-shadow: 0px 5px 90px 0px rgba(0, 0, 0, 0.1);
    padding: 60px 30px;
    transition: all ease-in-out 0.3s;
    border-radius: 18px;
    border-bottom: 5px solid var(--surface-color);
    height: 100%;
  }
  
  .services .service-item .icon {
    color: var(--contrast-color);
    background: var(--accent-color);
    margin: 0;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 28px;
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item h3 {
    font-weight: 700;
    margin: 10px 0 15px 0;
    font-size: 22px;
    transition: ease-in-out 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  @media (min-width: 1365px) {
    .services .service-item:hover {
      transform: translateY(-10px);
      border-color: var(--accent-color);
    }
  
    .services .service-item:hover h3 {
      color: var(--accent-color);
    }
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    padding: 120px 0;
    position: relative;
    clip-path: inset(0);
  }
  
  .call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 50%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    color: var(--default-color);
    font-size: 28px;
    font-weight: 700;
  }
  
  .call-to-action p {
    color: var(--default-color);
  }
  
  .call-to-action .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .call-to-action .cta-btn:hover {
    background: color-mix(in srgb, var(--accent-color) 90%, white 15%);
  }
  
  /*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
  .features .features-image {
    position: relative;
    min-height: 400px;
  }
  
  .features .features-image img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .features .features-item h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
  }
  
  .features .features-item i {
    font-size: 48px;
    color: var(--accent-color);
    margin-right: 20px;
    line-height: 0;
  }
  
  .features .features-item p {
    font-size: 15px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0;
  }
  
  /*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
  .clients .clients-wrap {
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    border-left: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  .clients .client-logo {
    background-color: var(--surface-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
    overflow: hidden;
  }
  
  .clients .client-logo img {
    padding: 50px;
    max-width: 80%;
    transition: 0.3s;
  }
  
  @media (max-width: 640px) {
    .clients .client-logo img {
      padding: 30px;
      max-width: 50%;
    }
  }
  
  .clients .client-logo:hover img {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
  .stats {
    position: relative;
  }
  
  .stats img {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .stats:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 20%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .stats .container {
    position: relative;
    z-index: 3;
  }
  
  .stats .subheading {
    text-align: center;
  }
  
  .stats .subheading h3 {
    font-weight: 700;
    font-size: 36px;
  }
  
  .stats .stats-item {
    padding: 30px;
    width: 100%;
  }
  
  .stats .stats-item span {
    font-size: 48px;
    display: block;
    color: var(--default-color);
    font-weight: 700;
  }
  
  .stats .stats-item p {
    padding: 0;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 16px;
    font-weight: 700;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
  .portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    font-family: var(--heading-font);
  }
  
  .portfolio .portfolio-filters li:hover,
  .portfolio .portfolio-filters li.filter-active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .portfolio .portfolio-filters li:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-filters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-filters li {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
  
  .portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: color-mix(in srgb, var(--surface-color), transparent 10%);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: 0.3s;
    line-height: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: var(--accent-color);
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
  .pricing .pricing-item {
    background-color: var(--surface-color);
    box-shadow: 0px 5px 90px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing .pricing-item h3 {
    padding: 15px;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
  }
  
  .pricing .pricing-item h4 {
    color: var(--accent-color);
    font-size: 42px;
    font-family: var(--default-font);
    font-weight: 500;
  }
  
  .pricing .pricing-item h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing .pricing-item h4 span {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing .pricing-item ul {
    padding: 20px 0;
    list-style: none;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing .pricing-item ul li {
    padding-bottom: 16px;
  }
  
  .pricing .pricing-item ul .na {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    text-decoration: line-through;
  }
  
  .pricing .pricing-item .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  
  .pricing .pricing-item .btn-buy {
    color: var(--accent-color);
    background-color: transparent;
    border: 2px solid var(--accent-color);
    display: inline-block;
    padding: 10px 40px 12px 40px;
    border-radius: 50px;
    font-size: 14px;
    font-family: var(--heading-font);
    font-weight: 600;
    transition: 0.3s;
  }
  
  .pricing .pricing-item .btn-buy:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .pricing .recommended .btn-buy {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .pricing .recommended .btn-buy:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
    border-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .pricing .recommended-badge {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 13px;
    padding: 3px 25px 6px 25px;
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    color: var(--accent-color);
    border-radius: 50px;
  }
  
  /*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
  .faq .content h3 {
    font-weight: 400;
    font-size: 34px;
  }
  
  .faq .content p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
  }
  
  .faq .faq-container {
    margin-top: 15px;
  }
  
  .faq .faq-container .faq-item {
    background-color: var(--surface-color);
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .faq .faq-container .faq-item:last-child {
    margin-bottom: 0;
  }
  
  .faq .faq-container .faq-item h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin: 0 30px 0 32px;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .faq .faq-container .faq-item h3 span {
    color: var(--accent-color);
    padding-right: 5px;
  }
  
  .faq .faq-container .faq-item h3:hover {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  
  .faq .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
  }
  
  .faq .faq-container .faq-item .faq-icon {
    position: absolute;
    top: 22px;
    left: 20px;
    font-size: 22px;
    line-height: 0;
    transition: 0.3s;
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .faq .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-active h3 {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
  }
  
  .faq .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Recent Posts Section
  --------------------------------------------------------------*/
  .recent-posts article {
    background: var(--surface-color);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .recent-posts .post-img {
    max-height: 240px;
    margin: -30px -30px 15px -30px;
    overflow: hidden;
  }
  
  .recent-posts .post-category {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 10px;
  }
  
  .recent-posts .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .recent-posts .title a {
    color: var(--heading-color);
    transition: 0.3s;
  }
  
  .recent-posts .title a:hover {
    color: var(--accent-color);
  }
  
  .recent-posts .post-author-img {
    width: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .recent-posts .post-author {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .recent-posts .post-date {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-item+.info-item {
    margin-top: 40px;
  }
  
  .contact .info-item i {
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .info-item:hover i {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .contact .php-email-form {
    height: 100%;
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: var(--surface-color);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 25%);
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .swiper-wrapper {
    height: auto;
  }
  
  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  
  .portfolio-details .swiper-button-prev:after,
  .portfolio-details .swiper-button-next:after {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  
  .portfolio-details .swiper-button-prev:hover:after,
  .portfolio-details .swiper-button-next:hover:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 575px) {
  
    .portfolio-details .swiper-button-prev,
    .portfolio-details .swiper-button-next {
      display: none;
    }
  }
  
  .portfolio-details .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-info h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    bottom: 0;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  
  .portfolio-details .portfolio-info ul strong {
    text-transform: uppercase;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
  }
  
  .portfolio-details .portfolio-info .btn-visit {
    padding: 8px 40px;
    background: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .portfolio-details .portfolio-info .btn-visit:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item {
    padding: 30px 30px 0 30px;
    position: relative;
    background: color-mix(in srgb, var(--default-color), transparent 97%);
    margin-bottom: 50px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    float: left;
    margin: 0 10px 0 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px 0;
    padding-top: 20px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h4 {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .portfolio-details .portfolio-description .testimonial-item p {
    font-style: italic;
    margin: 0 0 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .services-list {
    background-color: var(--surface-color);
    padding: 10px 30px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    margin-bottom: 20px;
  }
  
  .service-details .services-list a {
    display: block;
    line-height: 1;
    padding: 8px 0 8px 15px;
    border-left: 3px solid color-mix(in srgb, var(--default-color), transparent 70%);
    margin: 20px 0;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    transition: 0.3s;
  }
  
  .service-details .services-list a.active {
    color: var(--heading-color);
    font-weight: 700;
    border-color: var(--accent-color);
  }
  
  .service-details .services-list a:hover {
    border-color: var(--accent-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details h4 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--accent-color);
  }