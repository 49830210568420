.education-page {
    position: relative;
    
  }
  
  //🔹 Section Navigation (Now Below Main Menu)
  .education-nav {
    position: sticky;
    top: 80px; // Keeps it below the main nav
    z-index: 999;
    background: var(--primary);
    padding: 10px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 30px;
      margin: 0;
      padding: 10px 0;
  
      li {
        font-size: 1rem;
        font-weight: 600;
  
        a {
          color: var(--bs-light);
          text-decoration: none;
          padding: 8px 15px;
          border-radius: 5px;
          transition: background 0.3s ease, color 0.3s ease;
  
          &:hover {
            background: var(--bs-secondary);
            color: var(--bs-light);
          }
        }
      }
    }
  }
  // 🔹 Section Styling for Smooth Transitions
  .education-content {
    .education-section {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start; // Align content to the left
      justify-content: center;
      padding: 80px 20px;
      transition: opacity 0.6s ease-in-out;
  
      h2 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: var(--bs-dark);
        text-align: left;
      }
  
      p {
        font-size: 1.1rem;
        line-height: 1.6;
        color: var(--bs-gray-dark);
        max-width: 800px;
        margin: 0 auto 20px 0; // Align left and add spacing
        text-align: left;
      }
    }
  }
  
  // 🔹 Image Styling
  .image-column {
    text-align: left;
  
    img {
      max-width: 85%;
      border-radius: 10px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  
  // 🔹 Smooth Scrolling Effect
  html {
    scroll-behavior: smooth;
  }
  
  // 🔹 Updated Navigation Links Styling
  .edu-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--bs-primary);
    text-decoration: none;
    border: 2px solid var(--bs-primary);
    border-radius: 8px;
    margin: 5px;
    transition: all 0.3s ease-in-out;
  
    &:hover {
      background: var(--bs-primary);
      color: #fff;
    }
  }
  
  // 🔹 Section Spacing & Blockquote Styling
  .education-section {
    padding: 100px 0;

  
    h2 {
      text-align: left;
      font-size: 2.5rem;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 1.1rem;
      line-height: 1.6;
      color: var(--bs-gray-dark);
      text-align: left;
    }
  
    blockquote {
      font-style: italic;
      background: #fff;
      padding: 20px;
      border-left: 5px solid var(--bs-primary);
      margin: 20px auto;
      font-size: 1.1rem;
      max-width: 800px;
      text-align: left;
  
      cite {
        display: block;
        margin-top: 10px;
        font-weight: bold;
        font-size: 1rem;
        color: var(--bs-dark);
      }
    }
  }
  
  // 🔹 Responsive Fixes
  @media (max-width: 992px) {
    .education-nav {
      padding: 10px 0;
  
      ul {
        flex-wrap: wrap;
        gap: 15px;
      }
  
      li {
        font-size: 1rem;
      }
    }
  
    .education-section {
      padding: 60px 20px;
  
      h2 {
        font-size: 2rem;
      }
  
      p {
        font-size: 1rem;
      }
    }
  
    .image-column img {
      max-width: 100%;
    }
  }
  

  // Fieldwork Section (Now Flush with Menu)
.flush-top {
    min-height: 0 !important;
    margin-top: -20px; // Adjusts spacing to remove gap under menu
    padding-top: 80px;
 // Ensures section starts below navigation
  }
  
  // Fieldwork Layout Adjustments
  .fieldwork-image {
    text-align: center;
    img {
      max-width: 100%;
      border-radius: 10px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
  .fieldwork-text {
    text-align: left;
    padding: 20px;
  }
  

  iframe {
    width: 80% !important;
    height:300px !important;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    
}

@media (max-width: 768px) {
    iframe {
      width:100% !important;
    }

}

#art-education {
    background: #f8f9fa;
}  
  
  // Technology Integration Section
#technology-integration {
    padding: 80px 0;
    background: #f8f9fa;
  
    h2 {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: var(--bs-dark);
    }
  
    p {
      font-size: 1.1rem;
      line-height: 1.6;
      color: var(--bs-gray-dark);
    }
  
    // Video Column Styling
    .tech-videos {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .video-container {
        position: relative;
        width: 80%;
        background: #000;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  
        iframe {
          width: 100%;
          height: 300px;
          border-radius: 10px;
        }
      }
    }
  
    // 🔹 Mobile Optimization
    @media (max-width: 768px) {
      .tech-text {
        text-align: center;
      }
  
      .tech-videos {
        iframe {
          height: 200px;
        }
      }
    }
  }